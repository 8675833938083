<template>
  <div class="blog width-100">
    <heard-vue></heard-vue>
    <!-- 主体区域开始 -->
    <div class="width-100 posi-rel">
      <div class="blog-center">
        <!-- 顶部 -->
        <div class="space-between blog-heard width-100">
          <div class="width-50 blog-heard-text direction">
            <p>{{blogObj.title}}</p>
            <div class="blog-heard-text-center"   v-html="blogObj.content">
            </div>
          </div>
          <div class="width-50 blog-img">
            <img :src="blogObj.img" class="width-100 height-100" alt=""  />
          </div>
        </div>
        <!-- 搜索框 -->
        <div class="flex-center width-100 mb-6">
          <div class="search-input">
            <input
              type="text"
              placeholder="Search for Dropshipping products"
              v-model="query.title"
            />
          </div>
          <div class="flex-center bg-color search-button pointer" @click="serchClick">
            <img src="../../assets/blog-1.png" alt="" />
          </div>
        </div>
        <!-- 热门帖子 -->
        <div class="hot-title">Popular posts</div>
        <div class="width-100 space-between mb-6" style="align-items: start">
          <div class="hot-center">
            <div class="width-100 hot-item row" v-for="item in blogList" :key="item.id">
              <img :src="item.imgs" alt="" />
              <div class="height-100 column-between hot-text one width-100">
                <div class="hot-text-title">
                  <div>{{ item.title }}</div>
                  <div>{{ item.summary }}</div>
                </div>
                <div class="space-between hot-time width-100">
                  <div>{{ item.create_time }}</div>
                  <div class="main-color pointer" @click="navTo('/blog-detail',item.id)">
                    Read more>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="featured-center mb-3">
              <div class="featured-contact column-between width-100">
                <div class="row pointer">
                  <img src="../../assets/blog-2.png" class="pointer" alt="" @click="navToOne(siteObj.facebook)" />
                  <img src="../../assets/blog-3.png" class="pointer" @click="navToOne(siteObj.youtube)" alt="" />
                </div>
                <div>Follow us on social media</div>
              </div>
            </div>
            <div class="expand width-100" :style="{backgroundImage:`url(${homeBg})`}">
              <div class="width-100 height-100 direction expand-mask">
                <div>{{blogObj.redirct_title}}</div>
                <div  v-html="blogObj.redirct_content"></div>
                <div class="bg-color flex-center pointer" @click="navToOne(blogObj.redirct_url)">Yes, I'm here</div>
              </div>
            </div>
            <div class="width-100 featured-articles">
              <div class="articles-title width-100">
                <div class="main-color">Selected articles</div>
                <div></div>
              </div>
              <div
                class="width-100 row articles-item pointer"
                v-for="item in featuredList"
                :key="item.id"
                @click="navTo('/blog-detail',item.id)"
              >
                <img :src="item.imgs" alt="" />
                <div class="height-100 item-text column-between">
                  <div class="text-ellipsis">
                    {{ item.summary }}
                  </div>
                  <div>{{ item.create_time }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="width-100 flex-center">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="5"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
      <img
        src="../../assets/about-5.png"
        v-if="!emailShow"
        class="emain-img posi-abs pointer"
        alt=""
        @click="emailShow = true"
      />
      <mail-vue v-if="emailShow" @hide="emailShow = false"></mail-vue>
    </div>
    <!-- 主题区域结束 -->
    <feet-vue></feet-vue>
  </div>
</template>

<script>
import heardVue from "../../components/heard.vue";
import feetVue from "../../components/feet.vue";
import mailVue from "../../components/mail.vue";
import api from "../../api/index";
export default {
  components: {
    heardVue,
    feetVue,
    mailVue,
  },

  data() {
    return {
      emailShow: false,
      query: {
        is_hot: 0,
        title: "",
        page: 1,
        limit: 5,
      },
      total: 0,
      blogList: null,
      queryOne: {
        is_hot: 1,
        page: 1,
        limit: 4,
      },
      featuredList: null,
      loading:null,
      siteObj:null,
      blogObj:{},
      homeBg:""
    };
  },

  created() {
  
    this.getBlog();
    this.getBlogOne();
    this.getOtherSite()
    this.siteObj = JSON.parse(localStorage.getItem("siteObj"));
  },

  mounted() {},

  methods: {
    getOtherSite(){
      api.getOtherSite().then(res=>{
        const reg = new RegExp("\n", "g");
         res.data.blog.content = decodeURIComponent(res.data.blog.content).replace(
            reg,
            "<br>"
          );
            res.data.blog.redirct_content = decodeURIComponent(res.data.blog.redirct_content).replace(
            reg,
            "<br>"
          );
        this.blogObj=res.data.blog
        if(res.data.blog.redirct_img){
          this.homeBg=res.data.blog.redirct_img
        }else{
          this.homeBg=require('@/assets/blog-4.png')
        }
      })
    },
    getBlog() {
      this.loading = this.$loading({
        lock: true,
        text: "Under load...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      api.getBlog(this.query).then((res) => {
        this.total = res.data.total;
        this.blogList = res.data.data;
        setTimeout(()=>{
          this.loading.close();
        },500)
      }).catch(err=>{
        this.loading.close();
      })
    },
    getBlogOne() {
      api.getBlog(this.queryOne).then((res) => {
        this.featuredList = res.data.data;
      });
    },
    currentChange(e) {
      this.query.page = e;
      this.getBlog();
    },
    navTo(url,id) {
      this.$router.push({ path: url, query: { id } });
    },
    serchClick() {
      this.query.page = 1;
      this.getBlog();
    },
    navToOne(url){
      window.open(url,"_blank");
    }
  },
};
</script>
<style lang="less" scoped>
.blog {
  background: #f8f8f8;
  .blog-center {
    width: 1226px;
    margin: 0 auto;
    padding-bottom: 90px;
    .blog-heard {
      margin-bottom: 60px;
      align-items: stretch;
      .blog-heard-text {
        background: #f1f1f1;
        justify-content: center;
        padding-bottom: 10px;
        height: auto;
        p {
          width: 400px;
          font-weight: bold;
          color: #333333;
          font-size: 36px;
          margin-bottom: 20px;
        }
        .blog-heard-text-center {
          width: 400px;
          color: #333333;
          font-size: 14px;
          // text-align: justify;
        }
      }
      .blog-img{
        height: auto;
      }
    }
    .search-input {
      width: 476px;
      height: 48px;
      padding: 0 14px;
      border: 1px solid #d1d1d1;
      border-right: none;
      input {
        width: 100%;
        height: 100%;
      }
    }
    .search-button {
      width: 80px;
      height: 48px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .hot-title {
      font-weight: bold;
      color: #333333;
      font-size: 32px;
      margin-bottom: 12px;
    }
    .hot-center {
      width: 887px;
      .hot-item {
        height: 245px;
        background: #ffffff;
        margin-bottom: 1px;
        padding: 27px;
        img {
          width: 315px;
          height: 191px;
          margin-right: 16px;
        }
        .hot-text {
          font-weight: 400;
          color: #666;
          font-size: 16px;
          .hot-text-title {
            div:first-child {
              font-weight: bold;
              color: #333333;
              font-size: 24px;
              margin-bottom: 16px;
            }
          }
          .hot-time {
            color: #999999;
            div:last-child {
              font-size: 14px;
            }
          }
        }
      }
      .hot-item:last-child {
        margin-bottom: 0;
      }
    }
    .featured-center {
      width: 320px;
      max-width: 320px;
      .featured-contact {
        height: 114px;
        background: #ffffff;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        padding: 17px 0 7px 20px;
        img {
          width: 49px;
          height: 49px;
          margin-right: 25px;
        }
        font-weight: 400;
        color: #666666;
        font-size: 18px;
      }
    }
    .expand {
      margin-bottom: 36px;
      height: 372px;
      background-image: url("../../assets/blog-4.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      font-weight: bold;
      color: #ffffff;
      font-size: 24px;
      .expand-mask {
        padding-top: 72px;
        background-color: rgba(0, 0, 0, 0.16);
        div:nth-child(2) {
          margin: 18px 0 15px 0;
        }
        div:last-child {
          width: 255px;
          height: 42px;
          font-size: 16px;
          margin-top: 70px;
        }
      }
    }
    .featured-articles {
      background: #ffffff;
      .articles-title {
        height: 55px;
        padding-left: 15px;
        padding-top: 20px;
        font-size: 20px;
        div:last-child {
          width: 80px;
          height: 1px;
          margin-top: 2px;
          background: #ff652b;
        }
      }
      .articles-item {
        border-top: 1px solid rgba(112, 112, 112, 0.3);
        height: 104px;
        padding: 21px 60px 16px 21px;
        color: #333333;
        font-size: 14px;
        flex-wrap: nowrap;
        max-width: 320px;
        img {
          width: 80px;
          min-width: 80px;
          height: 67px;
          margin-right: 16px;
        }
        .item-text {
          div:last-child {
            color: #999999;
          }
        }
      }
    }
    /deep/.el-pagination.is-background .el-pager li {
      background: #ffffff;
      color: #666666;
    }
    /deep/.el-pagination.is-background .btn-next {
      background: #ffffff;
      color: #666666;
    }
    /deep/.el-pagination.is-background .btn-prev {
      background: #ffffff;
      color: #666666;
    }
    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
      background: #ff652b !important;
      color: #ffffff;
    }
  }
  .emain-img {
    left: 63px;
    bottom: 465px;
    width: 64px;
    height: 64px;
  }
}
</style>
